import { useState ,useEffect} from 'react'
import {Link, useLocation,useNavigate} from 'react-router-dom'

import '../css/main.css'

import { useData} from '../../../contexts/DataContext';



function Component() {

  const {fire} = useData();

    useEffect(() => {
      document.querySelectorAll('._menu li').forEach(e=>{
             e.addEventListener('click', handleMenuClick);
      }) 
      return () => {
         document.querySelectorAll('._menu li').forEach(e=>{
             e.removeEventListener('click', handleMenuClick);
         }) 
      };
    }, []);


   function handleMenuClick(){
          setShowMenu(false)
   }


	 const location = useLocation();
     const pathname = location.pathname
     const navigate=useNavigate()


   let [float,setFloat]=useState(false)
   let [showMenu,setShowMenu]=useState(false)

   useEffect(() => {
      document.addEventListener('scroll', handleScroll);
      return () => {
        document.removeEventListener('scroll', handleScroll);
      };
    }, []);


   function handleScroll(){
   	   setFloat(window.pageYOffset >= window.innerHeight ? true : false)
   }


  return (
     <div className={`_menu ${float ? "float" : ""} absolute top-0 left-0 flex justify-between w-full`}>
             <div className="_left flex">
                 <div className="_logo">
                     <a href={'/#home'}><h1><span className="long">Visum Loan Management System</span><span className="short">VLMS</span></h1></a>
	             </div>
	             <div className={`flex _links ${showMenu && 'active'}`}>
	                 <ul className="flex">
	                    <li><a href={'/#home'}>Home</a></li>
	                    <li><a href={'/#features'}>Recursos</a></li>
	                    <li><a href={'/#plans'}>Planos</a></li>
                      <li><a href={'/#join'}>Cadastrar</a></li>
                      <li><a href={'/#faq'}>Faq</a></li>
	                 </ul>
                   <div className="btn-close-menu" onClick={()=>setShowMenu(false)}>
                                   <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                   </div> 
                   <div className="bg-close" onClick={()=>setShowMenu(false)}>
                                 
                   </div> 
	             </div>
             </div>
             
             <div className="_auth flex">
                 <button className="_login"><Link to={'/#join'}>Cadastrar</Link></button>
                 <Link to={'/login'}><button className="_register"  onClick={fire.click('btn-entrar')}>Entrar</button></Link>
                 <div className="burger" onClick={()=>setShowMenu(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="30"><path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z"/></svg></div>
             </div>

     </div>
  )
}

export default Component