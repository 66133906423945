
import '../css/main.css'
import './style.css'
import Menu from '../common/menu.js';
import TopBar from "../common/topBar";
import Create from './create.js'
import DeleteDialog from './delete-dialog.js'
import { useEffect, useState } from 'react';
import DynamicGridExample from './table.js'
import CreateImportExcel from '../common/create-import-excel.js'
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext.js';
import * as XLSX from 'xlsx';

export default function Clients() {
const [activePage, setActivePage] = useState('Clientes');
const [create, setCreate] = useState(false);
const [edit, setEdit] = useState(null);
const [del, setDel] = useState(null);
const [tableSearch,setTableSeach]=useState('');
const [createImport, setCreateImport] = useState(false);
const {data,setData,makeRequest,setPrintData} = useData();
let initial_filters={start_date:'',end_date:'',show_only:'',micro:'',client:'',end_date_export:'',start_date_export:''}
const [filters,setFilters]=useState(initial_filters);
const [showExportDialog,setShowExportDialog]=useState(false);
const [filteredContent,setFilteredContent]=useState([]);

const {user} = useAuth()



function time(){
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    const currentMinute = currentDate.getMinutes();
    const formattedTime = `${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`;
    return formattedTime
  }
  
  function today(get_this_day) {
          const currentDate = get_this_day ? get_this_day : new Date();
          const year = currentDate.getFullYear();
          const month = String(currentDate.getMonth() + 1).padStart(2, '0');
          const day = String(currentDate.getDate()).padStart(2, '0');
          const formattedDate = `${year}-${month}-${day}`;
          return formattedDate
  }

  const exportToExcel = (type,printType) => {

    setShowExportDialog(false)

    let _data=data[type].filter(i=>filteredContent.map(f=>f.client_id).includes(i.client_id))
   
    if(filters.start_date){
        _data=_data.filter(i=>new Date(i.createdAt.split('T')[0]).getTime() >= new Date(filters.start_date).getTime())
    }

    if(filters.end_date){
        _data=_data.filter(i=>new Date(i.createdAt.split('T')[0]).getTime() <= new Date(filters.end_date).getTime())
    }

    let micro=data.microcredits.filter(i=>i.id==filters.micro)[0]?.name
    let name=(user.microcredit?.name ||  micro || 'VLMS') +` - Contratos de caixa ${today()} ${time()}`

    const mappedData = _data.map(item => ({
        'ID':item.id,
        'Cliente':item.client.name + " "+ item.client.last_name,
        'Financiado':new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.disbursement_value),
        'Prestação':new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.disbursement_value + item.fees),
        'Pago':new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.paid),
        'Estado de aprovação':item.approval_status=='pending' || !item.approval_status ? 'Pendente':  item.approval_status=="approved" ? 'Aprovado' :  item.approval_status=="parcial" ? 'Parcialmente' : 'Rejeitado',
        'Estado de pagamento':item.payment_status=='pending' || !item.payment_status ? 'Pendente':  item.payment_status=="paid" ? 'Pago' :  item.payment_status=="parcial" ? 'Parcialmente' : item.payment_status=="delayed" ? 'Atrasado' : 'Atrasado',
        'Juros de mora':new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.late_payment_interest),
        'Data de desembolso':item.disbursement_date,
        'Data de reembolso':item.refund_date,
        'juros':new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.fees),
        "Percentagem de Juros":item.fees_percentage +"%",
        "Bens associados":item.contract_assets?.length || 0,
        "Data do último pagamento":item.contract_payments.filter(i=>i.payday).reverse()[0]?.payday || "",
        "Criado por":item.by,
        'Dias em falta': item.payment_status=="paid" ? '-' : item.days_left == 0 ? "Hoje" : item.days_left < 0 ? - item.days_left+" dias atrás" : item.days_left,
        'Data de criação':item.createdAt.split('T')[0],
      }));

    const mappedDataPDF = _data.map(item => ({
        'Cliente':item.client.name + " "+ item.client.last_name,
        'Financiado':new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.disbursement_value),
        'Prestação':new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.disbursement_value + item.fees),
        'Pago':new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.paid),
        'Estado de pagamento':item.payment_status=='pending' || !item.payment_status ? 'Pendente':  item.payment_status=="paid" ? 'Pago' :  item.payment_status=="parcial" ? 'Parcialmente' : item.payment_status=="delayed" ? 'Atrasado' : 'Atrasado',
        "TRP":item.classe,
        "Juros de mora":new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.late_payment_interest)  || "",
        'Data de desembolso':item.disbursement_date,
        'Data de reembolso':item.refund_date,
        "Bens associados":item.contract_assets?.length || 0,
        "Data do último pagamento":item.contract_payments.filter(i=>i.payday).reverse()[0]?.payday || "",
        'Dias em falta': item.payment_status=="paid" ? '' : item.days_left == 0 ? "Hoje" : item.days_left < 0 ? - item.days_left+" dias atrás" : item.days_left,
        'Data de criação':item.createdAt.split('T')[0],
      }));
    

    if(printType=="pdf"){
        setPrintData({print:true,data:mappedDataPDF,name})
        return
    }


    const workbook = XLSX.utils.book_new();
    const sheetData = XLSX.utils.json_to_sheet(mappedData);
    XLSX.utils.book_append_sheet(workbook, sheetData, 'Sheet1');
    XLSX.writeFile(workbook, `${name}.xlsx`);
    
 };






return (
    <>
    <main className="dashboard-container">
        { <CreateImportExcel showD={(createImport || edit) ? true : false}  create={createImport} setCreate={setCreateImport} setEdit={setEdit} edit={edit} del={del} export_details={{name:'clientes',api:'clients'}}/>}
        { <Create showD={(create || edit) ? true : false}  create={create} setCreate={setCreate} setEdit={setEdit} edit={edit} del={del}/>}
         {<DeleteDialog del={del} showD={(del) ? true : false} setDel={setDel}/>}

        <div className="dashboard">

            <Menu activePage={activePage}/>

            <div className="main-dashboard">
               
                  <TopBar activePage={activePage}/>

                  <div className="center">

                       <div className="center_search">

                           <div className="left_side">
                               <div className="search_container">
                                     <div className="search-div">
                                        <input type="text" placeholder="Pesquisar..." value={tableSearch} onChange={(e)=>setTableSeach(e.target.value)}/>
                                       <div className="search-btn">
                                           <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"/></svg>
                                        </div>
                                     </div>
                               </div>
                           </div>


                           <div className="btn-add-item" onClick={()=>setCreate(true)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"></path></svg>
                                    <span>Adicionar</span>
                           </div>
                       </div>


                    <div className="extra-filters">

                        <div className="input-container">

                                <div className="inputs">
                                    <span>Data de inicio</span>
                                    <input type="date" value={filters.start_date} onChange={(e)=>setFilters({...filters,start_date:e.target.value})} />
                                </div>

                                <div className="inputs">
                                    <span>Data de fim</span>
                                    <input type="date" value={filters.end_date} onChange={(e)=>setFilters({...filters,end_date:e.target.value})} />
                                </div>


                                <div className="inputs">
                                    <span>Mostrar apenas</span>
                                    <select value={filters.show_only} onChange={e=>setFilters({...filters,show_only:e.target.value})}>
                                        <option  selected value="">Selecionar opção</option>
                                        <option value="on_time">Top 10 Clientes que Pagam no Prazo</option>
                                        <option value="late">Top 10 Clientes com Maior Atraso</option>
                                    </select>
                                </div>

                               {user.role == "admin" && <div className="inputs">
                                    <span>Microcrédito</span>
                                    <select value={filters.micro} onChange={e=>setFilters({...filters,micro:e.target.value})}>
                                        <option  selected value="">Selecionar opção</option>
                                        {data.microcredits.map(i=>(
                                             <option value={i.id}>{i.name}</option>
                                        ))}
                                    </select>
                                </div>}
                                
                        </div>

                        <div className="options">
                            <span onClick={()=>setFilters(initial_filters)}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="M440-122q-121-15-200.5-105.5T160-440q0-66 26-126.5T260-672l57 57q-38 34-57.5 79T240-440q0 88 56 155.5T440-202v80Zm80 0v-80q87-16 143.5-83T720-440q0-100-70-170t-170-70h-3l44 44-56 56-140-140 140-140 56 56-44 44h3q134 0 227 93t93 227q0 121-79.5 211.5T520-122Z"/></svg> </span>
                        </div>

                    </div>




                       <div className="dasboard-item-list-container">
                       <div className="top-section" style={{display:'flex'}}>
                                <div className="left-side" >
                                        {data.loaded.clients && <>
                                            <div className="show">
                                            <span>Resultados:</span>
                                            <label>{filteredContent.length}</label>
                                        </div>
                                          </>}

                                 </div>

                                            <div className="right-side">
                                                

                                                   <div className="extract-btn">

                                                   <div style={{display:'flex',alignItems:'center'}}>
                                                           <div className="btn" onClick={()=>exportToExcel('loans','pdf')}>
                                                                <span className="export export-excel page" style={{background:'rgb(74, 205, 53)'}}>
                                                                    <svg width="21" viewBox="0 0 24 24" style={{fill:'#fff'}} xmlns="http://www.w3.org/2000/svg">
                                                                       <path d="M9 12.5H10V10.5H11C11.2833 10.5 11.5208 10.4042 11.7125 10.2125C11.9042 10.0208 12 9.78333 12 9.5V8.5C12 8.21667 11.9042 7.97917 11.7125 7.7875C11.5208 7.59583 11.2833 7.5 11 7.5H9V12.5ZM10 9.5V8.5H11V9.5H10ZM13 12.5H15C15.2833 12.5 15.5208 12.4042 15.7125 12.2125C15.9042 12.0208 16 11.7833 16 11.5V8.5C16 8.21667 15.9042 7.97917 15.7125 7.7875C15.5208 7.59583 15.2833 7.5 15 7.5H13V12.5ZM14 11.5V8.5H15V11.5H14ZM17 12.5H18V10.5H19V9.5H18V8.5H19V7.5H17V12.5ZM8 18C7.45 18 6.97917 17.8042 6.5875 17.4125C6.19583 17.0208 6 16.55 6 16V4C6 3.45 6.19583 2.97917 6.5875 2.5875C6.97917 2.19583 7.45 2 8 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H8ZM8 16H20V4H8V16ZM4 22C3.45 22 2.97917 21.8042 2.5875 21.4125C2.19583 21.0208 2 20.55 2 20V6H4V20H18V22H4Z" />
                                                                    </svg>
                                                                </span>
                                                           </div>
                                                           <div className="btn" onClick={()=>exportToExcel('loans','excel')}>
                                                                <span  className="export export-excel page" style={{background:'rgb(74, 205, 53)'}}>
                                                                    <svg style={{fill:'#fff'}} xmlns="http://www.w3.org/2000/svg" width="21"  viewBox="0 0 24 24"><path d="M18 22a2 2 0 0 0 2-2v-5l-5 4v-3H8v-2h7v-3l5 4V8l-6-6H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12zM13 4l5 5h-5V4z"/></svg>
                                                                </span>
                                                               
                                                           </div>
                                                        </div>

                                                         <div style={{display:'none'}} className={`dialog ${showExportDialog && 'active'}`}>
                                                                <div className="input-container">

                                                                    <div className="inputs">
                                                                        <span>Data de inicio</span>
                                                                        <input type="date" value={filters.start_date_export} onChange={(e)=>setFilters({...filters,start_date_export:e.target.value})} />
                                                                    </div>

                                                                    <div className="inputs">
                                                                        <span>Data de fim</span>
                                                                        <input type="date" value={filters.end_date_export} onChange={(e)=>setFilters({...filters,end_date_export:e.target.value})} />
                                                                    </div>

                                                                    <div className="inputs">
                                                                        <span>Cliente</span>
                                                                        <select value={filters.client} onChange={e=>setFilters({...filters,client:e.target.value})}>
                                                                            <option  selected value="">Selecione cliente</option>
                                                                            {data.clients.map(i=>(
                                                                                <option value={i.client_id}>{i.name}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>

                                                                </div>
                                                                <div className={`options ${filters.client ? 'active':''}`}>
                                                                     <span style={{display:'none'}} onClick={()=>exportToExcel('transations')}>Extrair transações</span>
                                                                     <span onClick={()=>exportToExcel('loans')}>Extrair contratos</span>
                                                                </div>
                                                         </div>
                                                   </div>

                                                  
                                            
                                            </div>
                                            </div>

                             {<DynamicGridExample filteredContent={filteredContent} setFilteredContent={setFilteredContent} filters={filters} tableSearch={tableSearch} setEdit={setEdit} del={del} setDel={setDel}/>}

                       </div>

                  </div>

                  
            </div>
        </div>
    </main>
    </>
  );
}
