import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';
import toast from 'react-hot-toast';
import axios from 'axios';
import { useNavigate ,useLocation} from 'react-router-dom';
import Menu from './menu';
import CustomNoRowsOverlay from '../common/tableLoader'


export default function DataGridDemo({setEdit,setDel,del,tableSearch,filters,itemsPerPage,setFilteredContent}) {

const location = useLocation();
const currentPath = location.pathname;
    const {token,user} = useAuth();
    const {data,setData,makeRequest} = useData();
    const [openMenu, setOpenMenu] = React.useState(null);
    const [loadingApproval,setLoadingApproval]=React.useState(false);
    const [dataLoaded,setDataLoaded] = React.useState({});
    const handleOutsideClick = (event) => {
        if (!event.target.closest('.menu') &&  !event.target.closest('.menu-option')) {
          setOpenMenu(null)
          document.removeEventListener('click', handleOutsideClick);
        }
    };

    const  handleClickMenu = (id) => {
         document.addEventListener('click', handleOutsideClick);
         setOpenMenu(id)
    };

   

    function handleLoanApproval(id,approval_status) {

       if(loadingApproval)  return

      setTimeout(()=>{
         setOpenMenu(null)
         document.removeEventListener('click', handleOutsideClick);
      },10)

      

      setLoadingApproval(true)

      let approval_toast=toast.loading(`A ${approval_status=='approved' ? 'aprovar ' :'rejeitar'}...`)

       axios.post(`${process.env.REACT_APP_BASE_URL}/api/loans/handle-approval/`,{id,approval_status},{
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {
            toast.remove()
            toast.dismiss(approval_toast)
            toast.success(`impréstimo ${approval_status=='approved' ? 'aprovado ' :'rejeitado'}`)
            let loans=JSON.parse(JSON.stringify(data.loans))
            let index=data.loans.findIndex(i=>i.id==id)
            loans[index].approval_status=approval_status
            data.loans=loans
            setData(data)
            setLoadingApproval(false)
        })
        .catch(error => {
            setLoadingApproval(false)
            toast.remove()
            toast.dismiss(approval_toast)
            console.error('Error fetching user data:', error);
            try{
                   toast.error('Ocorreu um erro:'+error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                   toast.error('Error, try again!')
            }
           
        });      
    }

    const navigate = useNavigate();

    function search(array){

               function search_from_object(object,text){
                      text=tableSearch
                      let add=false
                      Object.keys(object).forEach(k=>{
                        if(typeof object[k]=="string" || typeof object[k]=="number"){
                           if(typeof object[k]=="number") object[k]=`${object[k]}`
                           if(object[k].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))){
                             add=true
                          }
                        }
                      })
                      return add
              }

            if (!array) return []
            let d=JSON.parse(JSON.stringify(array))
            let res=[]


            if(filters.payment_status.length){
                d=d.filter(i=>filters.payment_status.includes(i.payment_status))
            }

            if(filters.approval_status.length){
                d=d.filter(i=>filters.approval_status.includes(i.approval_status))
            }


            d.forEach((t,i)=>{
              t.client=t.client.name
              if(t['approval_status']=="approved" || t['payment_status']=="approved")  t['status']="aprovado"
              if(t['approval_status']=="delayed" || t['payment_status']=="delayed")    t['status']="Atrasado"
              if(t['approval_status']=="pending" || t['payment_status']=="pending")   t['status']="Pendente"
              if(t['approval_status']=="paid" || t['payment_status']=="paid")   t['status']="Pago"
              if(t['approval_rejected']=="rejected" || t['payment_status']=="rjected")   t['status']="rejeitado"

              if(search_from_object(t)) {
                  res.push(array.filter(j=>j.id==t.id)[0])
              }
           })

           return res
    }

    const columns = [
    {
      field: 'createdAt',
      headerName: 'Data',
      width: 200,
      editable: false,
      filterable: false,
      valueGetter: (params) =>new Date(params.row.createdAt).toISOString().split('T')[0] + " "+ new Date(params.row.createdAt).toISOString().split('T')[1].replace('.000Z','').slice(0,5) || "-",
    },
    {
    field: 'client.name',  
    headerName: 'Cliente',
    width: 180,
    renderCell: (params) => (
      <span>{params.row.client.name}</span>
    ),
    },
    {
      field: 'disbursement_value',
      headerName: 'Financiamento',
      width: 170,
      editable: true,
      valueGetter: (params) => new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(params.row.disbursement_value)  || "-",
      
    }
    ,
     {
      field: 'initial_amount',
      headerName: 'Prestação',
      width: 150,
      valueGetter: (params) => new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(params.row.disbursement_value + params.row.fees)  || "-", 
      editable: true,
    },
    
    {
      field: 'paid',
      headerName: 'Pago',
      width: 150,
      valueGetter: (params) => new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(params.row.paid)  || "-",
      
      editable: true,
    },
   
    {
      field: 'approval_status',
      headerName: 'Estado de aprovação',
      width: 150,
      valueGetter: (params) => params.row.approval_status || "pending",
      renderCell: (params) => (
        <div style={{cursor:'pointer'}} onClick={() => handleEdit(params.row)}>
            <div style={{ backgroundColor:!params.row.approval_status || params.row.approval_status=='pending' || params.row.approval_status=='parcial' ? '#F9CE66': params.row.approval_status=="approved" ? '#4ACD35' : '#ed143d85', color: '#111' , padding:'0.5rem',borderRadius:'2rem'}}>
              <span style={{display:'flex',minWidth:'60px',justifyContent:'center'}}>  {params.row.approval_status=='pending' || !params.row.approval_status ? 'Pendente':  params.row.approval_status=="approved" ? 'Aprovado' :  params.row.approval_status=="parcial" ? 'Parcialmente' : 'Rejeitado'}</span>
            </div>
        </div>
      )
    },
    {
      field: 'payment_status',
      headerName: 'Estado de pagamento',
      width: 150,
      valueGetter: (params) => params.row.payment_status || "pending",
      renderCell: (params) => (
        <div style={{cursor:'pointer'}} onClick={() => handleEdit(params.row)}>
            <div style={{ backgroundColor:!params.row.payment_status || params.row.payment_status=='pending' || params.row.payment_status=='parcial' ? '#F9CE66': params.row.payment_status=="paid" ? '#4ACD35' : '#ed143d85', color: '#111' , padding:'0.5rem',borderRadius:'2rem'}}>
              <span style={{display:'flex',minWidth:'60px',justifyContent:'center'}}>  {params.row.payment_status=='pending' || !params.row.payment_status ? 'Pendente':  params.row.payment_status=="paid" ? 'Pago' :  params.row.payment_status=="parcial" ? 'Parcialmente' : params.row.payment_status=="delayed" ? 'Atrasado' : 'Atrasado'}</span>
            </div>
        </div>
      )
    },
    {
      field: 'late_payment_interest',
      headerName: 'Juros de mora',
      width: 170,
      valueGetter: (params) => new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(params.row.late_payment_interest)  || "-", 
      editable: true,
    },
     {
      field: 'total_to_pay',
      headerName: 'Total por pagar',
      width: 150,
      valueGetter: (params) => new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(params.row.total_to_pay + params.row.late_payment_interest)  || "-",
      
      editable: true,
    },
    {
      field: 'days_left',
      headerName: 'Dias em falta',
      width: 170,
      renderCell: (params) => (
        <span>{params.row.payment_status=="paid" ? '-' : params.row.days_left == 0 ? "Hoje" : params.row.days_left < 0 ? - params.row.days_left+" dias atrás" : params.row.days_left}</span>
      )
    },
    
    {
      field: 'edit',
      headerName: '',
      width: 30,
      renderCell: (params) => (
        <div style={{cursor:'pointer'}} onClick={() => handleEdit(params.row)}>
              <svg style={{opacity:.6}}  xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>
       </div>
      )
    },
    {
      field: 'more',
      headerName: '',
      width: 130,
      renderCell: (params) => (
        <div style={{cursor:'pointer', position:'absolute'}} onClick={()=>handleClickMenu(params.row.id)}  className="menu">
                      {!loadingApproval && user.role=="manager"  && <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z"/></svg>}
                      <div className="menu-dropdown-row" style={{position:'absolute',display:openMenu==params.row.id ? 'block' :'none'}}>
                                    {params.row.approval_status!= "approved" && <span className={`menu-option ${params.row.approval_status=="approved" ? "disabled" :''}`} onClick={()=>handleLoanApproval(params.row.id,'approved')}><svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>Aprovar</span>}
                                    {params.row.approval_status!="approved" && <span className={`menu-option ${params.row.approval_status=="rejected" ? "disabled" :''}`} onClick={()=>handleLoanApproval(params.row.id,'rejected')}><svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>Rejeitar</span>}
                                    <span className={`menu-option`} onClick={() => handleDelete(params.row)}> <svg style={{opacity:.6}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>Excluir</span>
                      </div>
          </div>
      )
    }
  ];

  function handleEdit(row) {
      navigate('/loans/'+row.id)
      data.loanToEdit=row
      setData(data)
  }
  function handleDelete(row) {
       setDel({id:row.id,confirm:null})
  }

   React.useEffect(()=>{
    (async () => {
                 try{
                       let res = await makeRequest({ url:'loans', error: ``});
                       setRows(res)
                       dataLoaded.loans=true
                       setData({...data,loans:res,loaded:{...data.loaded,loans:true}})
                 }catch(e){}
    })();
  },[])


  const [rows,setRows]=React.useState([])
  const [loading,setLoading]=React.useState(false)

  React.useEffect(()=>{
            let content=search(data.loans)
            setFilteredContent(content)
            setRows(content)
    
  },[data,data.loans,tableSearch,filters])



  React.useEffect(()=>{
    if(del?.confirm){

      axios.delete(`${process.env.REACT_APP_BASE_URL}/api/loans/delete/`+del.id ,{
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {
            data.loans=data.loans.filter(i=>i.id!=del.id)
            toast.success('Contrato eliminado com sucesso')
            setData(data)
            setDel(null)
        })
        .catch(error => {
            setLoading(false)
            console.error('Error fetching user data:', error);
            try{
                   toast.error(error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                   toast.error('Error, try again!')
            }

            setDel(null)
        });

      
    }
  },[del])


  
  return (
    <Box sx={{ height:'400px', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: itemsPerPage,
            },
          },
        }}
        pageSizeOptions={[itemsPerPage]}
        //checkboxSelection
        disableColumnMenu
        disableSelectionOnClick
        //onSelectionModelChange={handleSelectionModelChange}
         localeText={{ noRowsLabel: <CustomNoRowsOverlay loading={!dataLoaded.loans ? true : false}/>}}
      />
    </Box>
  );
}
