import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const StackedBarChart = ({ data, label, labels}) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);


  useEffect(() => {
    if (chartRef && chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const newChartInstance = new Chart(chartRef.current, {
        type: 'bar',
        data: {

          labels: data[0].map((company) => company.name),
          datasets: [
            {
              label: labels?.[0] || 'Transações de entrada',
              data: data[0].map((company) => company.total),
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
              stack: 'Stack 1', // Added stack property for stacking
            },
             {
              label: labels?.[1] || 'Transações de saida' ,
              data: data[1].map((company) => company.total),
              backgroundColor: '#D5E1F8',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
              stack: 'Stack 2', // Added stack property for stacking
            },
            
          ],
        },
        options: {
          animation: false,
          responsive: true,
          scales: {
            x: {
              stacked: true, // Enable stacking for x-axis
              grid: {
                display: false,
              },
            },
            y: {
              stacked: true, // Enable stacking for y-axis
              grid: {
                display: false,
              },
              beginAtZero: true,
            },
          },
        },
      });

      chartInstance.current = newChartInstance;

      return () => {
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }
      };
    }
  }, [data]);

  return <canvas ref={chartRef} />;
};

export default StackedBarChart;
