import '../css/main.css'
import './style.css'
import Menu from '../common/menu.js';
import TopBar from "../common/topBar";
import Create from '../loans/create/create-asset.js'
import DynamicGridExample from './table.js'
import DeleteDialog from './delete-dialog.js'
import { useState , useEffect} from 'react';
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';

import * as XLSX from 'xlsx';

export default function Microcredits() {

const {token,user} = useAuth();
const {data,setData,setPrintData} = useData();

const [create, setCreate] = useState(false);
const [edit, setEdit] = useState(null);
const [del, setDel] = useState(null);

const [_in, setIn] = useState(0);
const [_out, setOut] = useState(0);
const [tableSearch,setTableSeach]=useState('');
const [_a, setAvailable] = useState(0);
const [_accounts, setAccounts] = useState([])
let initial_filters={start_date:'',end_date:'',category_id:'',status:'',type:''}
const [filters,setFilters]=useState(initial_filters);
const status_types=[{id:'retained',label:'Retido'},{id:'in',label:'Confiscado'}]
const [filterRes,setFilterRes]=useState({amount:0,confiscated:0,retained:0});
const [filteredContent,setFilteredContent]=useState([]);
const [totals,setTotals]=useState({amount:0,confiscated:0,retained:0});



function time(){
  const currentDate = new Date();
  const currentHour = currentDate.getHours();
  const currentMinute = currentDate.getMinutes();
  const formattedTime = `${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`;
  return formattedTime
}

function today(get_this_day) {
        const currentDate = get_this_day ? get_this_day : new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate
}


useEffect(()=>{
   let retained=filteredContent.filter(i=>i.status=="retained").length
   let confiscated=filteredContent.filter(i=>i.status=="confiscated").length
   let amount=filteredContent.filter(i=>i.amount).map(item => parseFloat(item.amount)).reduce((acc, curr) => acc + curr, 0);
   setFilterRes({...filterRes,
   retained,   
   confiscated,
   amount:new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount),
 })
 },[filteredContent])



 useEffect(()=>{
   let retained=data.inventory.filter(i=>i.status=="retained").length
   let confiscated=data.inventory.filter(i=>i.status=="confiscated").length
   let amount=data.inventory.filter(i=>i.amount).map(item => parseFloat(item.amount)).reduce((acc, curr) => acc + curr, 0);

   
   setTotals({...totals,
   retained,   
   confiscated,
   amount:new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount),
 })
 },[data.inventory,data])

 
 
 
 useEffect(()=>{
   if(data.inventory.length && !filters.end_date && !filters.start_date){
      setFilters({...filters,
      start_date:data.inventory[data.inventory.length - 1].createdAt.split('T')[0],
      end_date:data.inventory[0].createdAt.split('T')[0],
      
    })
   }  
 },[data.inventory,filters]) 





 const exportToExcel = (type) => {

    let _data=filteredContent 

    let name=user.microcredit.name +` - Inventário ${today()} ${time()}.xlsx`

    const mappedData = _data.map(item => ({
       'ID':item.id,
       'Client':item.client.name,
       'Estado':item.type=="confiscated" ? 'Confiscado' :'Retido',
       'Valor':(item.type=="out" && item.amount!=0 ?'-' :'')+""+item.amount,
       'Categoria':item.category_id ? data.settings.inventory_categories.filter(i=>i.id==item.category_id)[0].name : '-',
       'Descrição':item.description,
       'Data de aquisição':item.date_of_acquisition,
       'Número de registro':item.registration_number,
       'Data de criação':item.createdAt.split('T')[0],
    }));

    if(type=="pdf"){
      setPrintData({print:true,data:mappedData,name})
      return
     }


    const workbook = XLSX.utils.book_new();
    const sheetData = XLSX.utils.json_to_sheet(mappedData);
    XLSX.utils.book_append_sheet(workbook, sheetData, 'Sheet1');
    XLSX.writeFile(workbook, `${name}.xlsx`);
 };









useEffect(()=>{
  

},[data.inventory, data.settings, data, data.loaded])





return (
    <>
    <main className="dashboard-container">

          { <Create showD={(create || edit) ? true : false}  create={create} setCreate={setCreate} setEdit={setEdit} edit={edit} del={del}/>}
         {<DeleteDialog del={del} showD={(del) ? true : false} setDel={setDel}/>}

      

        <div className="dashboard">
            <Menu/>

            <div className="main-dashboard">
               
                  <TopBar activePage={'Caixa'}/>

                  <div className="center">


                        <div className="stat_page_2">
                             <div className="items">

                                  <div className="box">
                                    <div className="icon blue-icon">
                                   <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M200-80q-33 0-56.5-23.5T120-160v-451q-18-11-29-28.5T80-680v-120q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v120q0 23-11 40.5T840-611v451q0 33-23.5 56.5T760-80H200Zm0-520v440h560v-440H200Zm-40-80h640v-120H160v120Zm200 280h240v-80H360v80Zm120 20Z"></path></svg></div>
                                    <div className="center-content">
                                       <div className="name">Total de bens</div>
                                       <div className="total">{data.inventory.length}</div>
                                    </div>
                                 </div>


                                   <div className="box">
                                    <div className="icon orange-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M200-80q-33 0-56.5-23.5T120-160v-451q-18-11-29-28.5T80-680v-120q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v120q0 23-11 40.5T840-611v451q0 33-23.5 56.5T760-80H200Zm0-520v440h560v-440H200Zm-40-80h640v-120H160v120Zm200 280h240v-80H360v80Zm120 20Z"></path></svg></div>
                                    <div className="center-content">
                                       <div className="name">Bens retidos</div>
                                       <div className="total">{totals.retained}</div>
                                       </div>
                                    
                                 </div>


                                   <div className="box">
                                    <div className="icon green-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M200-80q-33 0-56.5-23.5T120-160v-451q-18-11-29-28.5T80-680v-120q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v120q0 23-11 40.5T840-611v451q0 33-23.5 56.5T760-80H200Zm0-520v440h560v-440H200Zm-40-80h640v-120H160v120Zm200 280h240v-80H360v80Zm120 20Z"></path></svg> </div>
                                    <div className="center-content">
                                       <div className="name">Bens confiscados</div>
                                      { /*<div className="total">{(_in - _out) > 0 ? new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(_in - _out) +' MT' : 0 }</div>*/}
                                      <div className="total">{totals.confiscated}</div>
                                       </div>
                                   
                                 </div>


                                 
                                 <div className="box">
                                    <div className="icon green-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M200-80q-33 0-56.5-23.5T120-160v-451q-18-11-29-28.5T80-680v-120q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v120q0 23-11 40.5T840-611v451q0 33-23.5 56.5T760-80H200Zm0-520v440h560v-440H200Zm-40-80h640v-120H160v120Zm200 280h240v-80H360v80Zm120 20Z"></path></svg></div>
                                    <div className="center-content">
                                       <div className="name">Valor adquirido</div>
                                      { /*<div className="total">{(_in - _out) > 0 ? new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(_in - _out) +' MT' : 0 }</div>*/}
                                      <div className="total">{totals.amount +' MT'}</div>
                                    </div>
                                   
                                 </div>

                             </div>
                       </div>



                       <div className="center_search">

                           <div className="search_container">
                                 <div className="search-div">
                                    <input type="text" placeholder="Pesquisar..." value={tableSearch} onChange={(e)=>setTableSeach(e.target.value)}/>
                                       <div className="search-btn">
                                       <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"/></svg>
                                    </div>
                                 </div>
                           </div>


                           <div style={{display:'none'}} className="btn-add-item" onClick={()=>setCreate(true)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"></path></svg>
                                    <span>Adicionar</span>
                           </div>
                       </div>


                            <div className="extra-filters">

                               <div className="input-container">

                                         <div className="inputs">
                                            <span>Data de inicio</span>
                                            <input type="date" value={filters.start_date} onChange={(e)=>setFilters({...filters,start_date:e.target.value})} />
                                        </div>

                                         <div className="inputs">
                                            <span>Data de fim</span>
                                            <input type="date" value={filters.end_date} onChange={(e)=>setFilters({...filters,end_date:e.target.value})} />
                                        </div>


                                       <div className="inputs">
                                           <span>Estado</span>
                                           <select  value={filters.status} onChange={e=>setFilters({...filters, status:e.target.value})}>
                                                <option selected value="">Selecionar Estado</option>
                                                {status_types.map(i=>(
                                                      <option key={i.id} selected={filters.status === i.id} value={i.id}>{i.label}</option>
                                                ))}
                                           </select>
                                        </div>

                                        <div className="inputs">
                                           <span>Categoria</span>
                                           <select value={filters.category_id} onChange={e=>setFilters({...filters, category_id:e.target.value})}>
                                                <option  selected value="">Selecionar Categoria</option>
                                                {(data.settings?.inventory_categories ? data.settings?.inventory_categories : [] ).map(i=>(
                                                      <option key={i.id} selected={filters.category_id === i.id} value={i.id}>{i.name}</option>
                                                 ))}
                                           </select>
                                        </div>
                                       

                                        <div className="inputs">
                                           <span>Cliente</span>
                                           <select value={filters.client_id} onChange={e=>setFilters({...filters, client_id:e.target.value})}>
                                                <option  selected value="">Selecionar Cliente</option>
                                                {(data.clients).map(i=>(
                                                      <option key={i.client_id} selected={filters.client_id === i.client_id} value={i.client_id}>{i.name}</option>
                                                 ))}
                                           </select>
                                        </div>
                                       
                                       
                                        
                                </div>

                                <div className="options">
                                   <span onClick={()=>setFilters(initial_filters)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="M440-122q-121-15-200.5-105.5T160-440q0-66 26-126.5T260-672l57 57q-38 34-57.5 79T240-440q0 88 56 155.5T440-202v80Zm80 0v-80q87-16 143.5-83T720-440q0-100-70-170t-170-70h-3l44 44-56 56-140-140 140-140 56 56-44 44h3q134 0 227 93t93 227q0 121-79.5 211.5T520-122Z"/></svg> </span>
                                </div>




                            </div>


                       <div className="dasboard-item-list-container">


                            <div className="top-section" style={{display:'flex'}}>
                                 <div className="left-side">
                                          <div className="show" style={{display:'none'}}>
                                             <span>Mostrar</span>
                                             <select>
                                                <option>10</option>
                                                <option>20</option>
                                                <option>30</option>
                                             </select>
                                          </div>
                                          {(data.loaded.settings && data.loaded.inventory) && (<> <div className="show">
                                            <span>Resultados:</span>
                                            <label>{filteredContent.length}</label>
                                          </div>

                                          <div  className="show">
                                            <span>Bens retidos:</span>
                                            <label>{filterRes.retained}</label>
                                          </div>

                                          <div  className="show">
                                            <span>Bens confiscados:</span>
                                            <label>{filterRes.confiscated}</label>
                                          </div>


                                          <div className="show">
                                            <span>Valor adquirido: </span>
                                            <label>{filterRes.amount} MT</label>
                                          </div>
                                           </>)}
                                          
                                    
                                 </div>
                                 <div className="right-side">
                                       <span onClick={()=>exportToExcel('pdf')} className="export export-excel page" style={{background:'rgb(74, 205, 53)'}}>
                                             <svg width="21" viewBox="0 0 24 24" style={{fill:'#fff'}} xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9 12.5H10V10.5H11C11.2833 10.5 11.5208 10.4042 11.7125 10.2125C11.9042 10.0208 12 9.78333 12 9.5V8.5C12 8.21667 11.9042 7.97917 11.7125 7.7875C11.5208 7.59583 11.2833 7.5 11 7.5H9V12.5ZM10 9.5V8.5H11V9.5H10ZM13 12.5H15C15.2833 12.5 15.5208 12.4042 15.7125 12.2125C15.9042 12.0208 16 11.7833 16 11.5V8.5C16 8.21667 15.9042 7.97917 15.7125 7.7875C15.5208 7.59583 15.2833 7.5 15 7.5H13V12.5ZM14 11.5V8.5H15V11.5H14ZM17 12.5H18V10.5H19V9.5H18V8.5H19V7.5H17V12.5ZM8 18C7.45 18 6.97917 17.8042 6.5875 17.4125C6.19583 17.0208 6 16.55 6 16V4C6 3.45 6.19583 2.97917 6.5875 2.5875C6.97917 2.19583 7.45 2 8 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H8ZM8 16H20V4H8V16ZM4 22C3.45 22 2.97917 21.8042 2.5875 21.4125C2.19583 21.0208 2 20.55 2 20V6H4V20H18V22H4Z" />
                                             </svg>
                                       </span>
                                         <span onClick={()=>exportToExcel()} className="export export-excel page" style={{background:'rgb(74, 205, 53)'}}>
                                         <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24"><path fill="#fff" d="M18 22a2 2 0 0 0 2-2v-5l-5 4v-3H8v-2h7v-3l5 4V8l-6-6H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12zM13 4l5 5h-5V4z"></path></svg></span>
                                         <span className="page">4</span>
                                 </div>
                            </div>


                              {<DynamicGridExample filters={filters} setFilteredContent={setFilteredContent} tableSearch={tableSearch} setEdit={setEdit} del={del} setDel={setDel}/>}


                       </div>

                  </div>

                  
            </div>
        </div>
    </main>
    </>
  );
}
