
import '../css/main.css'
import './style.css'
import Menu from '../common/menu.js';
import TopBar from "../common/topBar";
import CreateImportExcel from '../common/create-import-excel.js'
import DeleteDialog from './delete-dialog.js'
import { useState , useEffect} from 'react';
import DynamicGridExample from './table.js'
import { useNavigate } from 'react-router-dom';
import _Menu from './menu.js'
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';
import * as XLSX from 'xlsx';

export default function Managers() {

const {token,user} = useAuth();
const {data,setData,setPrintData} = useData();  

function time(){
  const currentDate = new Date();
  const currentHour = currentDate.getHours();
  const currentMinute = currentDate.getMinutes();
  const formattedTime = `${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`;
  return formattedTime
}


 function today(get_this_day) {
        const currentDate = get_this_day ? get_this_day : new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate
}


 const exportToExcel = (type) => {

    let _data=filteredContent 

    let name=user.microcredit.name +` - contractos ${today()} ${time()}`

    const mappedData = _data.map(item => ({
      'ID':item.id,
      'Cliente':item.client.name + " "+ item.client.last_name,
      'Financiado':new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.disbursement_value),
      'Prestação':new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.disbursement_value + item.fees),
      'Pago':new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.paid),
      'Estado de aprovação':item.approval_status=='pending' || !item.approval_status ? 'Pendente':  item.approval_status=="approved" ? 'Aprovado' :  item.approval_status=="parcial" ? 'Parcialmente' : 'Rejeitado',
      'Estado de pagamento':item.payment_status=='pending' || !item.payment_status ? 'Pendente':  item.payment_status=="paid" ? 'Pago' :  item.payment_status=="parcial" ? 'Parcialmente' : item.payment_status=="delayed" ? 'Atrasado' : 'Atrasado',
      'Juros de mora':new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.late_payment_interest),
      'Data de desembolso':item.disbursement_date,
      'Data de reembolso':item.refund_date,
      'juros':new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.fees),
      "Percentagem de Juros":item.fees_percentage +"%",
      "TRP":item.tpr ? (item.tpr) : 0,
      "Bens associados":item.contract_assets?.length || 0,
      "Data do último pagamento":item.contract_payments.filter(i=>i.payday).reverse()[0]?.payday || "",
      "Criado por":item.by,
      'Dias em falta': item.payment_status=="paid" ? '-' : item.days_left == 0 ? "Hoje" : item.days_left < 0 ? - item.days_left+" dias atrás" : item.days_left,
      'Data de criação':item.createdAt.split('T')[0],
     }));

     const mappedDataPDF = _data.map(item => ({
      'Cliente':item.client.name + " "+ item.client.last_name,
      'Financiado':new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.disbursement_value),
      'Prestação':new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.disbursement_value + item.fees),
      'Pago':new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.paid),
      'Estado de pagamento':item.payment_status=='pending' || !item.payment_status ? 'Pendente':  item.payment_status=="paid" ? 'Pago' :  item.payment_status=="parcial" ? 'Parcialmente' : item.payment_status=="delayed" ? 'Atrasado' : 'Atrasado',
      "TRP":item.classe,
      "Juros de mora":new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.late_payment_interest)  || "",
      'Data de desembolso':item.disbursement_date,
      'Data de reembolso':item.refund_date,
      "Bens associados":item.contract_assets?.length || 0,
      "Data do último pagamento":item.contract_payments.filter(i=>i.payday).reverse()[0]?.payday || "",
      'Dias em falta': item.payment_status=="paid" ? '' : item.days_left == 0 ? "Hoje" : item.days_left < 0 ? - item.days_left+" dias atrás" : item.days_left,
      'Data de criação':item.createdAt.split('T')[0],
   
    }));

     if(type=="pdf"){
      setPrintData({print:true,data:mappedDataPDF,name})
      return
     }


    const workbook = XLSX.utils.book_new();
    const sheetData = XLSX.utils.json_to_sheet(mappedData);
    XLSX.utils.book_append_sheet(workbook, sheetData, 'Sheet1');
    XLSX.writeFile(workbook, `${name}.xlsx`);
 };

const [activePage, setActivePage] = useState('gestores');
const [create, setCreate] = useState(false);
const [edit, setEdit] = useState(null);
const [del, setDel] = useState(null);
const navigate = useNavigate();
const [showFilters,setShowFilters]=useState(false);
const [filters,setFilters]=useState({approval_status:[],payment_status:[]});
const [filtersOn,setFiltersOn]=useState(false);
const [tableSearch,setTableSeach]=useState('');
const [itemsPerPage,setItemsPerPage]=useState(10);
const [filterRes,setFilterRes]=useState({lent:0,lacking:0,expected:0,paid:0});
const [filteredContent,setFilteredContent]=useState([]);
const [isOpen, setIsOpen] = useState(false);
const [position, setPosition] = useState({ x: 0, y: 0 });



 const [lent, setLent] = useState(0);
 const [paid, setPaid] = useState(0);
 const [expected, setExpected] = useState(0);
 const [lacking, setLacking] = useState(0);

 useEffect(()=>{
   let lent=0
   let paid=0
   let expected=0
   let lacking=0

   


   data.loans.forEach(i=>{if(i.approval_status=="approved"){lent+=i.disbursement_value}})
   data.loans.forEach(i=>{if(i.approval_status=="approved"){paid+=i.paid}})
   data.loans.forEach(i=>{if(i.approval_status=="approved"){lacking+=i.lacking}})
   data.loans.forEach(i=>{
      if(i.approval_status=="approved")  expected+=(i.total_to_pay + i.late_payment_interest)
   })

   setLent(lent)
   setPaid(paid)
   setExpected(expected)
   setLacking(lacking)
},[data.loans, data])




useEffect(()=>{

   let lent=0
   let paid=0
   let expected=0
   let lacking=0


   filteredContent.forEach(i=>{if(i.approval_status=="approved"){lent+=i.disbursement_value}})
   filteredContent.forEach(i=>{if(i.approval_status=="approved"){paid+=i.paid}})
   filteredContent.forEach(i=>{if(i.approval_status=="approved"){lacking+=i.lacking}})
   filteredContent.forEach(i=>{
      if(i.approval_status=="approved")  expected+=(i.total_to_pay + i.late_payment_interest)
   })

   setFilterRes({...filterRes,lent:new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(lent),
    paid:new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(paid),
    expected:new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(expected),
    lacking:new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(lacking)
 })


},[filteredContent])



   function handle_filters(column,option,value){

     let _filters=JSON.parse(JSON.stringify(filters))

      if(value){
            _filters[column].push(option)
      }else{
            _filters[column]=_filters[column].map(i=>i!=option ? i : "").filter(i=>i!=false)
      } 

      Object.keys(_filters).forEach(i=>{
          let status=false
          if(_filters[i].length){
             status=true
          }
          setFiltersOn(status)
      })

      setFilters(_filters)
   }

   function importExcel(){
       setCreate(true)
   }



   function clean_filters(){
     let _filters=JSON.parse(JSON.stringify(filters))

      Object.keys(_filters).forEach(i=>{
          _filters[i]=[]
      })

      setFilters(_filters)
      setFiltersOn(false)
      setShowFilters(false)

      document.querySelectorAll('.filters input').forEach(e=>e.checked=false)
   }
 

    useEffect(() => {
      document.addEventListener('click', handleOutsideClick);
      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }, [showFilters]);

    const toggleFilters = () => {
      setShowFilters(!showFilters)
    };

    const handleOutsideClick = (e) => {
      if (showFilters && !e.target.closest('.filter')) {
          setShowFilters(false);
      }
    };

    function go_to(argument) {
      data.currentPage="/loans/create"
      navigate('/loans/create')
    }

   
return (
    <>
    <main className="dashboard-container">

        { <CreateImportExcel showD={(create || edit) ? true : false}  create={create} setCreate={setCreate} setEdit={setEdit} edit={edit} del={del} export_details={{name:'contratos',api:'loans'}}/>}
        { <DeleteDialog showD={del ? true : false} del={del}  setDel={setDel}/>}
         
          

        <div className="dashboard">

            <Menu/>
            <div className="main-dashboard">

             <div className="__meunu"><_Menu items={[{id:1, label:'edit'}]} isOpen={isOpen} position={position} /></div>
                
               
                  <TopBar activePage={'Contratos'}/>

                  <div className="center">

                       <div className="stat_page_2">
                             <div className="items">

                                  <div className="box">
                                    <div className="icon blue-icon">
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="27"><path d="M444-200h70v-50q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-50h-70v50q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26q14 48 43.5 77.5T444-252v52Zm36 120q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>
                                    </div>
                                    <div className="center-content">
                                       <div className="name">Financiado</div>
                                       <div className="total">{!data.loaded.loans ? '-' : new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(lent) +' MT'}</div>
                                    </div>
                                 </div>
                                   <div className="box">
                                    <div className="icon green-icon">
                                       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="27"><path d="M444-200h70v-50q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-50h-70v50q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26q14 48 43.5 77.5T444-252v52Zm36 120q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>
                                    </div>
                                    <div className="center-content">
                                       <div className="name">Recebido</div>
                                       <div className="total">{!data.loaded.loans ? '-' : new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(paid) +' MT'}</div>
                                    </div>
                                 </div>


                                   <div className="box">
                                    <div className="icon red-icon">
                                       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="27"><path d="M444-200h70v-50q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-50h-70v50q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26q14 48 43.5 77.5T444-252v52Zm36 120q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>
                                    </div>
                                    <div className="center-content">
                                       <div className="name">Em falta</div>
                                       <div className="total">{!data.loaded.loans ? '-' : new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(lacking) +' MT'}</div>
                                    </div>
                                 </div>

                                 <div className="box">
                                    <div className="icon orange-icon">
                                           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="27"><path d="M444-200h70v-50q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-50h-70v50q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26q14 48 43.5 77.5T444-252v52Zm36 120q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>
                                    </div>
                                    <div className="center-content">
                                       <div className="name">Esperado</div>
                                       <div className="total">{!data.loaded.loans ? '-' : new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(expected) +' MT'}</div>
                                    </div>
                                 </div>
                             </div>
                       </div>

                       <div className="center_search">

                           <div className="left_side">
                               <div className="search_container">
                                     <div className="search-div">
                                       <input type="text" placeholder="Pesquisar..." value={tableSearch} onChange={(e)=>setTableSeach(e.target.value)}/>
                                        <div className="search-btn">
                                           <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"/></svg>
                                        
                                        </div>
                                     </div>

                                     
                               </div>

                               <div className={`filter ${showFilters ? 'show' :''} ${filtersOn ? 'on' :''}`}>
                                    <div className="btn-filter" onClick={toggleFilters}>
                                            <svg className="show" xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960" width="22"><path d="M440-120v-240h80v80h320v80H520v80h-80Zm-320-80v-80h240v80H120Zm160-160v-80H120v-80h160v-80h80v240h-80Zm160-80v-80h400v80H440Zm160-160v-240h80v80h160v80H680v80h-80Zm-480-80v-80h400v80H120Z"/></svg>
                                           <span>Filtros</span>
                                     </div>
                                     <div className="filters">
                                        <div className="top">
                                            <span className="title">Filtros</span><span className="clean" onClick={clean_filters}>Limpar</span>
                                        </div>
                                        
                                        <div className="column">
                                             <div className="name">Estado de pagamento</div>
                                             <div className="options">
                                                 <div><label><input type="checkbox" onChange={(e)=>handle_filters('payment_status','paid',e.target.checked)}/><span>Pago</span></label></div>
                                                 <div><label><input type="checkbox" onChange={(e)=>handle_filters('payment_status','pending',e.target.checked)} /><span></span>Pendente</label></div>
                                                 <div><label><input type="checkbox" onChange={(e)=>handle_filters('payment_status','delayed',e.target.checked)}/><span>Atrasado</span></label></div>
                                              </div>
                                        </div>

                                        <div className="column">
                                             <div className="name">Estado de aprovação</div>
                                             <div className="options">
                                                 <div><label><input type="checkbox" onChange={(e)=>handle_filters('approval_status','approved',e.target.checked)}/><span>Aprovado</span></label></div>
                                                 <div><label><input type="checkbox" onChange={(e)=>handle_filters('approval_status','pending',e.target.checked)} /><span></span>Pendente</label></div>
                                                 <div><label><input type="checkbox" onChange={(e)=>handle_filters('approval_status','rejected',e.target.checked)}/><span>Rejeitado</span></label></div>
                                             </div>
                                        </div>

                                     </div>
                               </div>

                                
                           </div>


                           <div className="btn-add-item" onClick={()=>go_to('/loans/create')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"></path></svg>
                                    <span>Adicionar</span>
                           </div>
                       </div>

                       <div className="dasboard-item-list-container">
                            <div className="top-section" style={{display:'flex'}}>

                                 <div className="left-side" >
                                          <div className="show" style={{display:'none'}}>
                                             <label>
                                             <span>Mostrar</span>
                                             <select onClick={(e)=>setItemsPerPage(e.target.value)}>
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                             </select>
                                             </label>
                                           </div>              

                                            {data.loaded.loans && <>
                                               <div className="show">
                                             <span>Resultados:</span>
                                             <label>{filteredContent.length}</label>
                                            </div>
                                              <div className="show">
                                               <span>Financiado:</span>
                                               <label>{filterRes.lent}</label>
                                              </div>

                                              <div className="show">
                                               <span>Recebido:</span>
                                               <label>{filterRes.paid}</label>
                                              </div>

                                              <div className="show">
                                               <span>Em falta:</span>
                                               <label>{filterRes.lacking}</label>
                                              </div>

                                              <div className="show">
                                               <span>Esperado:</span>
                                               <label>{filterRes.expected}</label>
                                              </div></>}
                                 </div>

                                 <div className="right-side">
                                        <span onClick={()=>importExcel()} className="export export-excel page" style={{background:'rgb(74, 205, 53)'}}>
                                                    <svg style={{fill:'#fff'}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 14V8l-6-6H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-4h-7v3l-5-4 5-4v3h7zM13 4l5 5h-5V4z"/></svg>
                                        </span>
                                       <span onClick={()=>exportToExcel('pdf')} className="export export-excel page" style={{background:'rgb(74, 205, 53)'}}>
                                             <svg width="21" viewBox="0 0 24 24" style={{fill:'#fff'}} xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9 12.5H10V10.5H11C11.2833 10.5 11.5208 10.4042 11.7125 10.2125C11.9042 10.0208 12 9.78333 12 9.5V8.5C12 8.21667 11.9042 7.97917 11.7125 7.7875C11.5208 7.59583 11.2833 7.5 11 7.5H9V12.5ZM10 9.5V8.5H11V9.5H10ZM13 12.5H15C15.2833 12.5 15.5208 12.4042 15.7125 12.2125C15.9042 12.0208 16 11.7833 16 11.5V8.5C16 8.21667 15.9042 7.97917 15.7125 7.7875C15.5208 7.59583 15.2833 7.5 15 7.5H13V12.5ZM14 11.5V8.5H15V11.5H14ZM17 12.5H18V10.5H19V9.5H18V8.5H19V7.5H17V12.5ZM8 18C7.45 18 6.97917 17.8042 6.5875 17.4125C6.19583 17.0208 6 16.55 6 16V4C6 3.45 6.19583 2.97917 6.5875 2.5875C6.97917 2.19583 7.45 2 8 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H8ZM8 16H20V4H8V16ZM4 22C3.45 22 2.97917 21.8042 2.5875 21.4125C2.19583 21.0208 2 20.55 2 20V6H4V20H18V22H4Z" />
                                             </svg>
                                       </span>
                                        
                                         <span onClick={()=>exportToExcel()} className="export export-excel page" style={{background:'rgb(74, 205, 53)'}}>
                                         <svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24"><path fill="#fff" d="M18 22a2 2 0 0 0 2-2v-5l-5 4v-3H8v-2h7v-3l5 4V8l-6-6H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12zM13 4l5 5h-5V4z"></path></svg></span>
                                         <span className="page">4</span>
                                 </div>
                            </div>
                          
                          {<DynamicGridExample setFilteredContent={setFilteredContent} itemsPerPage={itemsPerPage}  tableSearch={tableSearch} filters={filters}  setEdit={setEdit} del={del} setDel={setDel}/>}
                          
                       </div>

                  </div>

                  
            </div>
        </div>
    </main>
    </>
  );
}
