
import {Link, useLocation,useNavigate} from 'react-router-dom'
import { useState,useEffect } from 'react';
import { useAuth  } from '../../contexts/AuthContext';
import { useData } from '../../contexts/DataContext';


 const Menu = () => {
 const {user} = useAuth()
 const {data,  menuOpen , setMenuOpen} = useData()
 const [openDropDown, setOpenDropDown] = useState(''); 
 const navigate = useNavigate();

  const location = useLocation();
  const pathname = location.pathname

  function changeOpenDropdown(link,to){
        if(link == openDropDown){
              setOpenDropDown('')
          }else{
              setOpenDropDown(link)
              go_to(to)
        } 
  }

   useEffect(()=>{
       setOpenDropDown(pathname)
   },[pathname])



   function go_to(path) {
       data.currentPage=path
       navigate(path)
       setMenuOpen(false)
   }

  return (
    <>
     <div className={`btn_open_close_menu ${menuOpen ? 'close' :''}`} onClick={()=>setMenuOpen(!menuOpen)}>
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"></path></svg>
    </div>
    <div className={`left-menu ${menuOpen ? 'open' :''}`}>
    <div className="_c">
    <div className="top">
          <h1>{user.microcredit ? user.microcredit.name : "VLMS"}</h1>
    </div>

    <div className="btn-close-menu" onClick={()=>setMenuOpen(false)}>
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"></path></svg>
    </div>

   

    <div className="center">
       <div  onClick={()=>go_to('/dashboard')}  className={`_nav-link ${pathname=='/dashboard' || pathname=='/'? 'active' : ''}`} link_page="dashboard">
                <div className="main-link">
                <svg xmlns="http://www.w3.org/2000/svg" width="24"  viewBox="0 0 24 24"><path d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586 6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z"></path></svg>
                <span>Dashboard</span>
                </div>
        </div>
        {user && user.role == "admin" && <div onClick={()=>go_to('/microcredits')}   className={`_nav-link ${pathname=='/microcredits' ? 'active' : ''}`}  link_page="microcredits">
                      <div className="main-link">
                          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960"><path d="M640-160v-280h160v280H640Zm-240 0v-640h160v640H400Zm-240 0v-440h160v440H160Z"/></svg>
                          <span>Microcreditos</span>
                      </div>
         </div>}

        {user && (user.role=="manager" || user.role=="admin") && (<div  className={`_nav-link  ${openDropDown=='users' ? 'open' : ''}  ${pathname=='/clients' || pathname=='/operators' || pathname=='/managers'  ? 'open active' : ''} `} link_page="users" >
                    <div className="main-link" onClick={() => changeOpenDropdown('users','/clients')}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120-240h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 320Zm0-400Z"/></svg>
                        <span>Utilizadores</span>
                        <label className="expand"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"/></svg></label>
                    </div>
      
                    <div className="dropdown-options">
                           <span onClick={()=>go_to('/clients')}  className={`o ${pathname=='/clients' ? 'active' : ''}`}>
                                Clientes
                           </span>
                           <span onClick={()=>go_to('/operators')} className={`o ${pathname=='/operators' ? 'active' : ''}`}>
                                Operadores
                           </span>
                           {user && user.role == "admin" && <span onClick={()=>go_to('/managers')} className={`o ${pathname=='/managers' ? 'active' : ''}`}>
                                Gestores
                           </span>}
                    </div>
        </div>)}


        {user && (user.role=="manager" || user.role=="operator" || user.role=="client") && (<div  className={`_nav-link  ${openDropDown=='loans' ? 'open' : ''}  ${pathname=='/loans/create' || pathname.startsWith('/loans/') || pathname=='/loans/settings' || pathname=='/loans' || pathname=="/loans/template"  ? 'open active' : ''} `} link_page="loans" >
                            <div className="main-link" onClick={() => changeOpenDropdown('loans','/loans')}> 
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-200h80v-40h40q17 0 28.5-11.5T600-280v-120q0-17-11.5-28.5T560-440H440v-40h160v-80h-80v-40h-80v40h-40q-17 0-28.5 11.5T360-520v120q0 17 11.5 28.5T400-360h120v40H360v80h80v40ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-560v-160H240v640h480v-480H520ZM240-800v160-160 640-640Z"/></svg>
                                <span>Gestão de contratos</span>
                                <label className="expand"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"/></svg></label>
                            </div>
              
                            <div className="dropdown-options">
                                   <span  onClick={()=>go_to('/loans')} className={`o ${pathname=='/loans' ? 'active' : ''}`}>
                                       Contratos
                                   </span>
                                   {user && (user.role=="manager" || user.role=="operator") && <span onClick={()=>go_to('/loans/create')}  className={`o ${pathname=='/loans/create' ? 'active' : ''}`}>
                                       Criar
                                   </span>}
                                  {user && user.role == "manager" && <span onClick={()=>go_to('/loans/settings')} className={`o ${pathname=='/loans/settings' ? 'active' : ''}`}>
                                       Definições
                                  </span>}
                                  {user && user.role == "client" && <span className={`o ${pathname=='/loans/settings' ? 'active' : ''}`}>
                                       Solicitar
                                  </span>}

                                  {<span onClick={()=>go_to('/loans/template')}  className={`o ${pathname=='/loans/template' ? 'active' : ''}`}>
                                       Modelo
                                  </span>}
                           </div>
         </div>)}


         {user && (user.role=="manager" || user.role=="operator") && (<div  className={`_nav-link  ${openDropDown=='cash-management' ? 'open' : ''}  ${pathname=='/cash-management/stats' || pathname=='/cash-management/settings' || pathname=='/cash-management'  ? 'open active' : ''} `} link_page="cash-management" >
                            <div className="main-link" onClick={() => changeOpenDropdown('cash-management','/cash-management')}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M444-200h70v-50q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-50h-70v50q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26q14 48 43.5 77.5T444-252v52Zm36 120q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                                <span>Gestão de caixa</span>
                                <label className="expand"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"/></svg></label>
                            </div>
              
                            <div className="dropdown-options" d={'/cash-management'}>
                                   <span  onClick={()=>go_to('/cash-management')} className={`o ${pathname=='/cash-management' ? 'active' : ''}`}>
                                       Caixa
                                   </span>
                                    <span  onClick={()=>go_to('/cash-management/stats')} className={`o ${pathname=='/cash-management/stats' ? 'active' : ''}`}>
                                       Relatórios
                                   </span>
                                   {user && user.role == "manager" && <span  onClick={()=>go_to('/cash-management/settings')} className={`o ${pathname=='/cash-management/settings' ? 'active' : ''}`}>
                                       Definições
                                   </span>}
                           </div>
         </div>)}



         {user && ((user.role=="manager" || user.role=="operator") && (user.microcredit.plan=="gold" || user.microcredit.plan=="premium")) && (<div  className={`_nav-link  ${openDropDown=='inventory' ? 'open' : ''}  ${pathname=='/inventory' || pathname=='/inventory/settings' || pathname=='/inventory'  ? 'open active' : ''} `} link_page="inventory" >
                            <div className="main-link" onClick={() => changeOpenDropdown('inventory','/inventory')}>
                               
                               <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M200-80q-33 0-56.5-23.5T120-160v-451q-18-11-29-28.5T80-680v-120q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v120q0 23-11 40.5T840-611v451q0 33-23.5 56.5T760-80H200Zm0-520v440h560v-440H200Zm-40-80h640v-120H160v120Zm200 280h240v-80H360v80Zm120 20Z"/></svg>
                                <span>Gestão de inventário</span>
                                <label className="expand"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"/></svg></label>
                            </div>
              
                            <div className="dropdown-options" d={'/inventory'}>
                                   <span  onClick={()=>go_to('/inventory')} className={`o ${pathname=='/inventory' ? 'active' : ''}`}>
                                      Bens
                                   </span>
                                   {user && user.role == "manager" && <span  onClick={()=>go_to('/inventory/settings')} className={`o ${pathname=='/inventory/settings' ? 'active' : ''}`}>
                                      Definições
                                   </span>}
                           </div>
         </div>)}


         {user && (((user.role=="manager" || user.role=="operator") && (user.microcredit.plan=="gold" || user.microcredit.plan=="premium")) || user.role=="admin") && (<div  className={`_nav-link  ${openDropDown=='risk-management' ? 'open' : ''}  ${pathname=='/risk-management/blacklist' || pathname=='/risk-management/my-clients' || pathname=="/risk-management/analysis" || pathname=="/risk-management/settings" ? 'open active' : ''} `} link_page="risk-management">
                            
                            <div className="main-link" onClick={() => changeOpenDropdown('risk-management','/risk-management/analysis')}>
                                
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h168q13-36 43.5-58t68.5-22q38 0 68.5 22t43.5 58h168q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm280-590q13 0 21.5-8.5T510-820q0-13-8.5-21.5T480-850q-13 0-21.5 8.5T450-820q0 13 8.5 21.5T480-790ZM200-200v-560 560Z"/></svg>
                                <span>Gestão de risco</span>
                                <label className="expand"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"/></svg></label>
                            </div>
              
                            <div className="dropdown-options" d={'/cash-management'}>
                                   <span  onClick={()=>go_to('/risk-management/analysis')} className={`o ${pathname=='/risk-management/analysis' ? 'active' : ''}`}>
                                      Análise de risco
                                   </span>
                                   <span  onClick={()=>go_to('/risk-management/blacklist')} className={`o ${pathname=='/risk-management/blacklist' ? 'active' : ''}`}>
                                       Blacklist
                                   </span>
                                   {user.role=="manager" && <span  onClick={()=>go_to('/risk-management/my-clients')} className={`o ${pathname=='/risk-management/my-clients' ? 'active' : ''}`}>
                                                                          Meus clientes
                                    </span>}
                                    {user && user.role == "manager" && <span  onClick={()=>go_to('/risk-management/settings')} className={`o ${pathname=='/risk-management/settings' ? 'active' : ''}`}>
                                      Definições
                                   </span>}
                           </div>

         </div>)}


         {user && user.role == "operator" && <div onClick={()=>go_to('/clients')}  className={`_nav-link ${pathname=='/clients' ? 'active' : ''}`} link_page="clients">
                    <div className="main-link">
                       <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120-240h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 320Zm0-400Z"/></svg>
                       <span>Clientes</span>
                    </div>
         </div>}


         <div onClick={()=>go_to('/settings')}  className={`_nav-link ${pathname=='/settings' ? 'active' : ''}`} link_page="settings">
           <div className="main-link">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm70-80h79l14-106q31-8 57.5-23.5T639-327l99 41 39-68-86-65q5-14 7-29.5t2-31.5q0-16-2-31.5t-7-29.5l86-65-39-68-99 42q-22-23-48.5-38.5T533-694l-13-106h-79l-14 106q-31 8-57.5 23.5T321-633l-99-41-39 68 86 64q-5 15-7 30t-2 32q0 16 2 31t7 30l-86 65 39 68 99-42q22 23 48.5 38.5T427-266l13 106Zm42-180q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Zm-2-140Z"/></svg>
              <span>Configurações</span>
           </div>
          </div>

     </div>


    <div className="bottom">

     
        
    </div>
    <div className="right-side"></div>
    </div>
</div> 
</>
  )
};

export default Menu;
