import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './contexts/AuthContext';
import { DataProvider } from './contexts/DataContext';
import { Toaster } from 'react-hot-toast';
import BasicTable from './components/Print/table';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
     <AuthProvider>
	     <DataProvider>
	        <Toaster/>
			<BasicTable/>
	    	<App />
	    </DataProvider>
    </AuthProvider>
);

reportWebVitals();
