
import { useEffect, useState} from 'react';
import { useData } from '../../../contexts/DataContext';
import { useAuth } from '../../../contexts/AuthContext';
import axios from 'axios';
import toast from 'react-hot-toast';

const Create = ({create,setCreate, edit , setEdit, del ,showD}) => {

const [formData,setFormData]=useState({id:'',name:'',number:'',condition:'',from:'tmrp'})

const [loading,setLoading]=useState(false)

const {data, setData} = useData();
const {token, user} = useAuth();



useEffect(()=>{
    if(edit){
       setFormData({...edit,from:'tmrp'})
       setCreate(false)
    }
},[edit])

useEffect(()=>{
    if(create){
       setEdit(false)
       setFormData({id:'',name:'',number:'',condition:'',from:'tmrp'})
    }
},[create])


function handle_manager(){

    if(edit){

      if(!formData.name || !formData.number || !formData.condition) {
           toast.error('Preencha todos os campos')
           return
      }

      setLoading(true) 

       axios.post(`${process.env.REACT_APP_BASE_URL}/api/tmrp/settings/categories/update`,{
         microcredit_id:user?.microcredit?.id,
         classes:data.settings.tmrp.classes.map(i=>{
            if(i.id==formData.id){
                return {...formData,number:parseFloat(formData.number)}
            }else{
                return i
            }
         })
       }, {
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {
             let classes=response.data
             data.settings.tmrp.classes=classes
             toast.success('Classe actualizada!')
             setData(data)
             setFormData({id:'',name:''})
             setEdit(null)
             setLoading(false)
             window.location.reload()  
        })
        .catch(error => {
            setLoading(false)
            console.error('Error data:', error);

            try{
                   toast.error(error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                   toast.error('Error, try again!')
            }
            
        });

    }

    
}
   
return (
 <div className={`dialog-container-bg ${showD ? 'show' :''}`}>
  <div className="bg-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}></div>
<div  className={`create-microcredit ${loading && 'loading'}`}>
                  <div className="top-title">{edit ? 'Editar' :' Adicionar'} classe</div>  
                  <div className="btn-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}>
                         <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                  </div>

                  <div className="input-container">
                     <div className="inputs">
                           <span>Nome</span>
                           <input value={formData.name} onChange={e=>setFormData({...formData,name:e.target.value})} type="text" placeholder="Nome da categoria"/>
                     </div>
                     <div className="inputs"></div>
                     <div className="inputs">
                           <span>Condição</span>
                           <select value={formData.condition} onChange={e=>setFormData({...formData,condition:e.target.value})} value={formData.condition}>
                              <option value="" selected disabled>Selecione</option>
                              <option value="more_than">{`>=`} a</option>
                              <option value="less_than">{`<=`} a</option>
                           </select>
                     </div>
                     <div className="inputs">
                           <span>Número</span>
                           <input value={formData.number} onChange={e=>setFormData({...formData,number:e.target.value})} type="text" placeholder="Nome da categoria"/>
                     
                     </div>
                  </div>

                 
                     
                 




              <div className="options">
                  <button className="cancel" onClick={()=>(edit ? setEdit(false) : setCreate(false) )}>Cancelar</button>
                  <div className="div_btn">
                     <button className="save" onClick={handle_manager}><span>{edit? 'Actualizar' : 'Adicionar'}</span></button>
                     <span className="loader"></span>
                  </div>
              </div>

        </div>
         </div>


)
};

export default Create;
