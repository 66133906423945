import React, { useEffect } from 'react'
import { useData } from '../../contexts/DataContext';

function BasicTable() {

  const data=useData()



  useEffect(()=>{

    if(data.print_data.print){
        data.setPrintData({...data.print_data,print:false})
        window.print()
    }

  },[data.print_data.print])

  return (
    <div
      print="true"
      style={{
        position: 'fixed',
        overflowX: 'auto',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
        borderRadius: '0.375rem',
        top:'0',
        left:'0',
        zIndex:'9999',
        width:'100%'
      }}
      className="print_table"
    >
      <h2>{data.print_data.name}</h2>
      <br/>
      <table style={{ width: '100%', fontSize: '0.875rem', textAlign: 'left', direction: 'rtl' }}>
        <thead style={{ fontSize: '0.75rem', textTransform: 'uppercase', backgroundColor: '#f9fafb', color: '#4a5568' }}>
          <tr>
            {Object.keys((data.print_data.data[0] || {})).map((i,_i)=>(
               <th key={_i} style={{ padding: '0.5rem 0.5rem' }}>{i}</th>
            ))}
          </tr>
        </thead>
        <tbody>

            {data.print_data.data.filter((i,_i)=>_i<=20).map((i,_i)=>(
                    <tr style={{ backgroundColor: '#ffffff', borderBottom: '1px solid #e5e7eb' }}>
                          {Object.keys(i).map((f,_f)=>(
                            <td style={{ padding: '0.5rem 0.5rem' }}>{data.print_data.data[_i][f]}</td>
                          ))}
                    </tr>
            ))}
          
          
          
        </tbody>
      </table>
    </div>
  );
}

export default BasicTable;
